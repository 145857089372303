$mobile: 768px;
$visible: 1232px;

$background: #252526;
$white: #ffffff;
$black: #000000;
$gray:  #707070;
$grayTransparent:  rgba(0, 0, 0, 0.44);
$error: #ffe7e6;
$errorPoint: #db4b46;

@mixin desktop {
    @media screen and (min-width: #{$mobile}) {
        @content; } }

#Main {
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    background-color: $white;
    background-image: url("../assets/images/pattern.png");
    background-size: auto;
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    &::before {
        z-index: 0;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-image: url("../assets/images/background_gradient.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        opacity: 0.6; }
    * {
        font-family: 'Asap', Helvetica, Arial, sans-serif; }
    #musicBox {
        z-index: 9;
        position: fixed;
        bottom: 16px;
        left: 24px;
        width: auto;
        height: 24px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        opacity: 0.8;
        &:hover {
            opacity: 1; }
        #musicPlay {
            position: relative;
            width: 24px;
            height: 100%;
            background-image: url("../assets/icons/play_arrow.svg");
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat; }
        #volumeWrap {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            #volumeIcon {
                position: relative;
                width: 20px;
                height: 100%;
                margin: 0 4px 0 16px;
                background-image: url("../assets/icons/volume_up.svg");
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat; }
            .volumeControl {
                width: 20px;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center; } }
        #visualizer {
            width: auto;
            height: 100%;
            padding-left: 16px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            div {
                height: 0;
                margin-left: 4px;
                width: 3px;
                background-color: $black; } }
        #bgm {
            width: 0.1px;
            height: 0.1px;
            visibility: hidden; } }
    #scrollAnimate {
        position: relative;
        bottom: 20px;
        width: 120px;
        height: 100px;
        opacity: 0.8;
        transition: all 0.5s ease;
        mix-blend-mode: difference;
        filter: invert(100%);
        > p {
            position: relative;
            bottom: 8px;
            font-size: 0.7em;
            letter-spacing: 0.1em;
            font-weight: bold;
            text-align: center; }
        .arrow {
            position: absolute;
            top: -80px;
            left: 50%;
            transform: translate(-50%,-50%); }
        .arrow span {
            display: block;
            width: 25px;
            height: 25px;
            border-bottom: 5px solid $black;
            border-right: 5px solid $black;
            transform: rotate(45deg);
            margin: -15px;
            animation: scrollDown 1.4s infinite; }
        .arrow span:nth-child(2) {
            animation-delay: -0.2s; }
        .arrow span:nth-child(3) {
            animation-delay: -0.4s; } }
    #musicStateWrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        #musicState {
            position: relative;
            width: 30vw;
            height: 40vh;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            animation: fadeout 1.4s;
            animation-iteration-count: 1;
            filter: blur(4px);
            opacity: 0; } }
    #mainPageTitle {
        position: absolute;
        bottom: 8vh;
        right: 8vw;
        opacity: 1;
        mix-blend-mode: difference;
        filter: invert(100%);
        p {
            font-size: 4.6em;
            font-weight: 700;
            margin-top: 16px;
            text-transform: uppercase;
            text-align: right;
            filter: blur(0px);
            &:nth-child(1) {
                font-size: 1.6em;
                font-weight: 400; } } } }

@keyframes scrollDown {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px); }
    50% {
        opacity: 1; }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px); } }

@keyframes fadeout2 {
    0% {
        opacity: 1;
        filter: blur(0px); }
    100% {
        opacity: 0;
        filter: blur(32px); } }
@keyframes fadeout {
    0% {
        opacity: 0.6;
        filter: blur(4px); }
    100% {
        opacity: 0;
        filter: blur(40px); } }
