$mobile: 768px;
$visible: 1232px;

$background: #252526;
$white: #ffffff;
$black: #000000;
$gray:  #707070;
$grayTransparent:  rgba(0, 0, 0, 0.44);
$error: #ffe7e6;
$errorPoint: #db4b46;

@mixin desktop {
    @media screen and (min-width: #{$mobile}) {
        @content; } }

.titlePageWrap.pageWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 2;
    .titleWrap {
        position: relative;
        width: 100%;
        flex: 1;
        display: flex;
        .background {
            position: relative;
            width: 100%;
            flex: 1;
            display: flex;
            overflow: hidden;
            .videoWrap {
                z-index: 0;
                position: absolute;
                top: -50%;
                left: -50%;
                width: 200%;
                height: 200%;
                .video {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: auto;
                    min-height: 50%;
                    min-width: 50%; } }
            .dark {
                z-index: 0;
                width: 100%;
                flex: 1;
                background-color: rgba(0, 0, 0, 0.6); } }
        .overlayWrap {
            position: absolute;
            width: 100%;
            height: 100%;
            color: $white;
            .titleBox {
                position: absolute;
                top: 66px;
                left: 10px;
                width: 400px;
                .title {
                    text-transform: uppercase;
                    font-size: 12.5em;
                    word-break: break-all;
                    font-weight: 700;
                    line-height: 170px; } }
            > .lineVertical {
                position: absolute;
                top: 0;
                left: 409px;
                width: 2px; }
            .contentsWrap {
                position: absolute;
                top: 76px;
                left: 427px;
                min-width: 200px;
                p {
                    font-size: 0.75em;
                    font-weight: 300;
                    text-transform: uppercase;
                    letter-spacing: 0.07em;
                    font-family: "Roboto Condensed";
                    b {
                        display: inline-block;
                        font-weight: 700; } } }
            .indexWrap {
                position: absolute;
                width: 100%;
                height: 100%;
                .lineVertical {
                    position: absolute;
                    bottom: 0;
                    left: 409px;
                    width: 2px;
                    height: 48px; }
                .index {
                    position: absolute;
                    bottom: 30px;
                    left: 427px;
                    font-size: 1.125em;
                    letter-spacing: 0.10em;
                    line-height: 20px;
                    text-transform: uppercase; } } } } }
