$mobile: 768px;
$visible: 1232px;

$background: #252526;
$white: #ffffff;
$black: #000000;
$gray:  #707070;
$grayTransparent:  rgba(0, 0, 0, 0.44);
$error: #ffe7e6;
$errorPoint: #db4b46;

@mixin desktop {
    @media screen and (min-width: #{$mobile}) {
        @content; } }

*:not([type=checkbox]) {
    -webkit-appearance: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
    transition: all 0.4s cubic-bezier(.5, 1.8, .5, .8);
    font-size: 1em;
    background-position: center center;
    background-size: contain; }

*:not([type=password]) {
    font-family: 'Roboto', 'NanumSquare', 'Malgun Gothic', 'Sans-serif';
 }    // 지원 가능 weight: 100, 300

.condensed {
    font-family: 'Roboto Condensed', 'NanumSquare', 'Malgun Gothic', 'Sans-serif';
 }    // 지원 가능 weight: 300, 700

input[type=button] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none; }

input:focus, textarea:focus, select:focus {
    outline: none; }

html, body {
    color: $black;
    font-size: 14px;
    scroll-behavior: smooth;
    @include desktop {
        font-size: 16px; } }

#root {
    height: 100vh;
    overflow: hidden; }

.sticky, .scrollmagic-pin-spacer {
    transition: all 0s; }

a, a:visited, a:active, a:hover {
    color: $black;
    text-decoration: none;
    cursor: pointer; }

.hiddenDisplay {
    display: none !important; }

.hidden {
    z-index: -999 !important;
    transform: translate(0, -70px) !important;
    opacity: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 auto !important; }

.clickable {
    cursor: pointer; }

.hiddenVisible {
    visibility: hidden; }

#onTheTop {
    z-index: 9;
    position: fixed;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    bottom: 24px;
    right: 24px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.42);
    background-color: rgba(255, 255, 255, 0.24);
    cursor: pointer;
    mix-blend-mode: difference;
    filter: invert(100%);
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
        width: 80%;
        height: 80%;
        border-radius: 50%;
        background-image: url("../../assets/icons/play_arrow.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: rotate(-90deg);
        opacity: 0.6;
        &:hover {
            opcaity: 1; } } }
