#nav {
    z-index: 9;
    position: fixed;
    top: 48px;
    right: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
        width: 32px;
        height: 32px;
        margin-bottom: 64px;
        background-image: url('../assets/images/logo.png'); }
    ul {
        li {
            margin-bottom: 32px;
            font-size: 1em;
            line-height: 16px;
            font-weight: 300;
            text-align: center;
            text-transform: uppercase; } } }
