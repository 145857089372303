$mobile: 768px;
$visible: 1232px;

$background: #252526;
$white: #ffffff;
$black: #000000;
$gray:  #707070;
$grayTransparent:  rgba(0, 0, 0, 0.44);
$error: #ffe7e6;
$errorPoint: #db4b46;

@mixin desktop {
    @media screen and (min-width: #{$mobile}) {
        @content; } }

#emailAddress {
    text-transform: lowercase; }
#section0Title {
    .titleWrap {
        .overlayWrap {
            > .lineVertical {
                height: 223px; }
            .contentsWrap {
                p {
                    line-height: 26px; } } } } }
#section0Contents {
    .contentsWrap {
        .mainWrap {
            &.subWrap {
                margin-top: 0px;
                max-width: 450px; }
            .filesWrap {
                margin-top: 100px;
                .subTitle {
                    text-transform: uppercase;
                    font-size: 1.5em;
                    font-weight: 700;
                    letter-spacing: 0.07em;
                    line-height: 60px; }
                .explanation {
                    font-size: 0.75em;
                    line-height: 26px; }
                .files {
                    margin-top: 42px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    &:last-child {
                        margin-top: 12px; }
                    .file {
                        width: 36px;
                        height: 40px;
                        background-position: center center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        cursor: pointer; }
                    .docxKr {
                        background-image: url("../../assets/icons/doc_kr_icon.png"); }
                    .hwpKr {
                        background-image: url("../../assets/icons/hwp_kr_icon.png"); }
                    .pdfKr {
                        background-image: url("../../assets/icons/pdf_kr_icon.png"); }
                    .docxEn {
                        background-image: url("../../assets/icons/doc_en_icon.png"); }
                    .hwpEn {
                        background-image: url("../../assets/icons/hwp_en_icon.png"); }
                    .pdfEn {
                        background-image: url("../../assets/icons/pdf_en_icon.png"); } } } }
        .introduceWrap {
            width: 100%;
            max-width: 1080px;
            margin-top: 120px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .introduceflex {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .icon {
                    width: 120px;
                    height: 120px;
                    background-position: center center;
                    background-size: contain;
                    background-repeat: no-repeat; }
                .subTitle {
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 1.5em;
                    margin-top: 22px; }
                .introduce {
                    margin-top: 34px;
                    p {
                        font-size: 0.75em;
                        font-weight: 300;
                        line-height: 22px;
                        text-align: center; } }
                &:nth-child(1) {
                    .icon {
                        background-image: url("../../assets/images/introduce_1.png"); } }
                &:nth-child(2) {
                    .icon {
                        background-image: url("../../assets/images/introduce_2.png"); } }
                &:nth-child(3) {
                    .icon {
                        background-image: url("../../assets/images/introduce_3.png"); } }
                &:nth-child(4) {
                    .icon {
                        background-image: url("../../assets/images/introduce_4.png"); } } } } } }
