#loadingContainer {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    background-image: url("../assets/images/pattern.png");
    background-size: auto;
    background-repeat: repeat;
    background-color: #222;
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    &::before {
        z-index: 0;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-image: url("../assets/images/loading_gradient.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        opacity: 0.32;
        mix-blend-mode: darken; }
    .logo {
        width: 80px;
        height: 80px;
        background-image: url("../assets/images/logo_white.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat; }
    .waviy {
        margin-top: 60px;
        -webkit-box-reflect: below -8px linear-gradient(transparent, rgba(255, 255, 255, .2));
        span {
            position: relative;
            display: inline-block;
            letter-spacing: 0.2em;
            font-size: 1.3em;
            color: #fff;
            text-transform: uppercase;
            animation: waviy 1s infinite;
            animation-delay: calc(.1s * var(--i)); } } }

@keyframes waviy {
    0%, 40%, 100% {
        transform: translateY(0); }
    20% {
        transform: translateY(-8px); } }
