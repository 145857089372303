$mobile: 768px;
$visible: 1232px;

$background: #252526;
$white: #ffffff;
$black: #000000;
$gray:  #707070;
$grayTransparent:  rgba(0, 0, 0, 0.44);
$error: #ffe7e6;
$errorPoint: #db4b46;

@mixin desktop {
    @media screen and (min-width: #{$mobile}) {
        @content; } }

.contentsPageWrap.pageWrap {
    background-color: $white;
    justify-content: flex-start;
    .contentsWrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 120px 0;
        > .title {
            font-size: 2.5em;
            font-weight: 700;
            text-transform: uppercase; }
        .withLink {
            cursor: pointer;
            &::after {
                content: "";
                position: absolute;
                width: 56px;
                height: 48px;
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url("../../../assets/icons/link.svg"); } }
        .mainWrap {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: auto;
            max-width: 620px;
            margin-top: 120px;
            font-weight: 300;
            // align-items: flex-start
            .contents {
                font-size: 0.75em;
                font-weight: 300;
                line-height: 22px; }
            .leftWrap {
                flex: 1;
                margin-right: 32px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                text-align: right; }
            .lineVertical {
                width: 2px;
                background-color: #707070; }
            .rightWrap {
                flex: 1;
                margin-left: 32px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                text-align: left; } }
        .mockup {
            margin-top: 120px;
            height: 700px;
            width: calc(100% - 64px);
            margin: 0 32px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat; }
        .swiper-container {
            margin-top: 120px;
            width: calc(100% - 32px);
            max-width: 1200px;
            margin: 0 16px;
            height: 400px;
            @include desktop {
                height: 600px; }
            .swiper-button-next, .swiper-button-prev {
                color: #000000; }
            .swiper-wrapper {
                .swiper-slide {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    > div, video, img {
                        width: 90%;
                        height: 90%;
                        background-position: center center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-color: rgba(0, 0, 0, 0.14);
                        outline: none; } } } } }
    &.applicationPageWrap {
        .contentsWrap {
            .swiper-container {
                .swiper-wrapper {
                    .swiper-slide {
                        width: 30%; } } } } } }
